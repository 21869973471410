@tailwind base;
@tailwind components;
@tailwind utilities;

pre {
    @apply py-2 overflow-auto text-xs bg-black border sm:text-sm dark:border-coal-light dark:bg-white bg-opacity-5;
    margin-bottom: 0.75rem !important;
}

.comment-body a {
    @apply text-blue-700 underline focus:bg-blue-700 dark:focus:bg-blue-300 dark:text-blue-300 focus:bg-opacity-10;
}

body {
    @apply overflow-y-scroll bg-white dark:bg-coal-dark;
}

/* Mobile only CSS */
@media (max-width: 640px) {
  .comment-body {
    text-align: left !important;
  }
}

/* Untitled Sans */
@font-face {
  font-family: 'Untitled-Sans';
  src: url('../src/fonts/untitled-sans/untitled-sans-bold-italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled-Sans';
  src: url('../src/fonts/untitled-sans/untitled-sans-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled-Sans';
  src: url('../src/fonts/untitled-sans/untitled-sans-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled-Sans';
  src: url('../src/fonts/untitled-sans/untitled-sans-medium-italic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled-Sans';
  src: url('../src/fonts/untitled-sans/untitled-sans-regular-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled-Sans';
  src: url('../src/fonts/untitled-sans/untitled-sans-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Tiempos Text */
@font-face {
  font-family: 'Tiempos';
  src: url('../src/fonts/tiempos-text/tiempos-text-bold-italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos';
  src: url('../src/fonts/tiempos-text/tiempos-text-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos';
  src: url('../src/fonts/tiempos-text/tiempos-text-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos';
  src: url('../src/fonts/tiempos-text/tiempos-text-medium-italic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos';
  src: url('../src/fonts/tiempos-text/tiempos-text-regular-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos';
  src: url('../src/fonts/tiempos-text/tiempos-text-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
